import moment from "moment-timezone";

function currentDateTime(getWhat = "both") {
    const date = new Date()
    if (getWhat == "both")
        return date.toLocaleString()
    else if (getWhat == "date") {
        return date.toLocaleDateString()
    }
}

function convertToDate(datetime) {
    //datetime = datetime.replace(/-/g, "/")
    if (datetime) {
        var date = new Date(datetime);
    } else {
        var date = new Date();
    }
    var opDate = date.getTime() / 1000;
    return opDate;
}

function convertToFilterDate(datetime, setToEnd = false) {
    //datetime = datetime.replace(/-/g, "/")
    var date = new Date();
    if (datetime) {
        date = new Date(datetime);
        if (setToEnd) { //to set starting of next day
            date.setDate(date.getDate() + 1)
        }
    }

    date.setHours(5, 30, 0, 0)
    var opDate = date.getTime() / 1000;
    return opDate;
}

function getDiffInMin(from, to) {
    return Math.abs(to - from) / 60
}

function getDateTime(unixts) {
    let lrtime = moment.unix(unixts).format("h:mm:ss a")
    let lrdate = moment.unix(unixts).format("DD/MM/YYYY")
    return { date: lrdate, time: lrtime }
}

function convertNumToTime(number) {
    try {
        // Check sign of given number
        var sign = (number >= 0) ? 1 : -1;

        // Set positive value of number of sign negative
        number = number * sign;

        // Separate the int from the decimal part
        var hour = Math.floor(number);
        var decpart = number - hour;

        var min = 1 / 60;
        // Round to nearest minute
        decpart = min * Math.round(decpart / min);

        var minute = Math.floor(decpart * 60) + '';

        // Add padding if need
        if (minute.length < 2) {
            minute = '0' + minute;
        }

        // Add Sign in final result
        sign = sign == 1 ? '' : '-';

        // Concate hours and minutes
        const time = sign + hour + ':' + minute;

        return time;
    } catch (E) {
        return "00:00"
    }
}

function historyDate(lastXdays = 5, type) {
    if (type != "currentDay") {
        var cdate = new Date();
        cdate.setHours(5, 30, 0, 0)
        const cTS = cdate.getTime()

        const lastDate = new Date()
        lastDate.setDate(lastDate.getDate() - lastXdays)
        // if(lastXdays == 0){
        lastDate.setHours(5, 30, 0, 0)
        // }
        const lastXDayTS = lastDate.getTime()

        return [lastXDayTS / 1000, cTS / 1000];
    } else {
        var cdate = new Date();
        const cTS = cdate.getTime()

        const lastDate = new Date()
        lastDate.setDate(lastDate.getDate() - lastXdays)
        // if(lastXdays == 0){
        lastDate.setHours(5, 30, 0, 0)
        // }
        const lastXDayTS = lastDate.getTime()

        return [lastXDayTS / 1000, cTS / 1000];
    }
}

function getRandomInt(min, max) {
    const minCeiled = Math.ceil(min);
    const maxFloored = Math.floor(max);
    return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
}

export {
    convertToDate,
    historyDate,
    getDiffInMin,
    getDateTime,
    convertToFilterDate,
    convertNumToTime,
    currentDateTime,
    getRandomInt
}