import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { get, post } from './service';

const initialState = {
    firmwareData: {
        status: null,
        data: null
    },
    deleteDeviceData: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'firmwareData',
    initialState,
    reducers: {
        setFirmwareData(state, action) {
            state.firmwareData = action.payload
        },
        setDeleteStataus(state, action) {
            state.deleteDeviceData = action.payload
        }
    }
});

export const { reducer } = slice;

export const getRegisteredDevices = () => async (dispatch) => {
    dispatch(slice.actions.setFirmwareData({ ...initialState.firmwareData, status: "loading" }));

    get(API.base_url + "phed/debugging/getRegisteredDevices")
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setFirmwareData(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setFirmwareData({ ...errResponse, status: "failed" }))
        });
};

export const deleteDeviceData = (deleteParameters) => async (dispatch) => {
    dispatch(slice.actions.setDeleteStataus({ ...initialState.deleteDeviceData, status: "loading" }));

    post(API.base_url + "phed/debugging/deleteDeviceDetails", deleteParameters)
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDeleteStataus(response));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDeleteStataus({ ...errResponse, status: "failed" }))
        });
};

export default slice;