import { Button, Grid, TextField, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { priColor } from "../../../../constants";
import { getDistrict, getDivision } from "../../../../slices/location";
import {
  getAvgSchemeStats,
  getOperationalData,
  updateAvgSchemeStats,
} from "../../../../slices/schemeReport";
import { historyDate } from "../../../../utils/helper";
import * as Yup from "yup";
import Loader from "../../../../components/Loader/Loader";

function devimalFormat(v) {
  if (Number(v)) {
    return v.toFixed(2);
  } else {
    return v;
  }
}

const fields = [
  {
    name: "Functional Schemes",
    fname: "functional",
    ext: "(No's)",
  },
  { name: "Non Functional Schemes", fname: "nonFunctional", ext: "(No's)" },
  {
    name: "Non Reporting Schemes",
    fname: "nonReporting",
    ext: "(No's)",
    disabled: true,
    note: "(Non Reporting Schemes = Total Schemes – (Functional Schemes + Non Functional Schemes)",
  },
  {
    name: "Avg Pump Running",
    fname: "avgMotorRunning",
    ext: "(Hrs)",
    note: "(Decimals Allowed, This can not be > 20Hrs and This can not be = 0Hrs.)",
  },
  {
    name: "Avg Electricity",
    fname: "avgElectricity",
    ext: "(Hrs)",
    note: "(Decimals Allowed, This can not be > 24Hrs, This can not be < 5Hrs.)",
  },
  {
    name: "Avg Water Consumption",
    fname: "avgWaterConsumption",
    ext: "(KL)",
    note: "(Decimals Allowed, This can not be > 120 KL, This can not be < 70 KL)",
    disabled: true,
  },
];

function SchemeData() {
  const [currentDistrict, setCurrentDistrict] = useState(0);
  const [currentDivision, setCurrentDivision] = useState(0);

  const [allDivision, setAllDivision] = useState([]);
  const [allDist, setAllDist] = useState([]);

  const dispatch = useDispatch();
  const { district, division } = useSelector((state) => state.location);
  const { operationalDataReport, avgSchemeStats, avgStatUpdate } = useSelector(
    (state) => state.schemeReport
  );
  const [formData, setFormData] = useState(null);
  const [actualValues, setActualValues] = useState({});

  const handleDistrictChange = function (e) {
    setCurrentDistrict(e.target.value);
    dispatch(getDivision(e.target.value));
    // console.log(">>111", e.target.value)
  };

  const handleDivisionChange = function (e) {
    setCurrentDivision(e.target.value);
    // console.log(">>111", e.target.value)
  };

  useEffect(() => {
    let initialVal = {
      functional: 0,
      nonFunctional: 0,
      nonReporting: 0,
      avgMotorRunning: 0,
      avgElectricity: 0,
      avgWaterConsumption: 0,
    };
    if (operationalDataReport && operationalDataReport.status == "SUCCESS") {
      if (operationalDataReport.data) {
        let tempActualValues = { ...actualValues };
        setFormData({ ...tempActualValues, ...operationalDataReport.data });
      } else {
        let tempFormData = { ...formData };
        setFormData({ ...tempFormData, ...initialVal });
      }
    }

    if (operationalDataReport.actualData) {
      let tempActualValues = { ...actualValues };
      setActualValues({
        ...tempActualValues,
        ...operationalDataReport.actualData,
      });
    } else {
      let tempActualValues = { ...actualValues };
      setActualValues({
        ...tempActualValues,
        ...initialVal,
      });
    }
  }, [operationalDataReport]);

  useEffect(() => {
    if (avgSchemeStats && avgSchemeStats.status == "SUCCESS") {
      let initialVal = {
        avgMotorRunning: 0,
        avgElectricity: 0,
        avgWaterConsumption: 0,
      };
      if (avgSchemeStats.actualData) {
        let tempActualValues = { ...actualValues };
        setActualValues({ ...tempActualValues, ...avgSchemeStats.actualData });
      } else {
        let tempActualValues = { ...actualValues };
        setActualValues({ ...tempActualValues, ...initialVal });
      }
    }
  }, [avgSchemeStats]);

  useEffect(() => {
    dispatch(getDistrict());
  }, []);

  useEffect(() => {
    if (district.data) setAllDist(district.data);
  }, [district]);

  useEffect(() => {
    if (division.data) setAllDivision(division.data);
  }, [division]);

  const fetchData = () => {
    if (currentDistrict) {
      dispatch(
        getOperationalData(
          { district: currentDistrict, division: currentDivision },
          historyDate(3, "currentDay")
        )
      );
      dispatch(
        getAvgSchemeStats(currentDistrict, currentDivision, historyDate(1))
      );
      // if (props.districtId != 0) {
      //   dispatch(getDeviceStats(props.districtId, props.divId, historyDate(1)))
      //   dispatch(getTarget(props.districtId, props.divId))
      // }
    }
  };

  useEffect(() => {
    // if (currentDistrict) {
    //   dispatch(
    //     getOperationalData(
    //       { district: currentDistrict, division: currentDivision },
    //       historyDate(3, "currentDay")
    //     )
    //   );
    //   dispatch(
    //     getAvgSchemeStats(currentDistrict, currentDivision, historyDate(1))
    //   );
    //   // if (props.districtId != 0) {
    //   //   dispatch(getDeviceStats(props.districtId, props.divId, historyDate(1)))
    //   //   dispatch(getTarget(props.districtId, props.divId))
    //   // }
    // }
    fetchData();
  }, [currentDistrict, currentDivision]);

  useEffect(() => {
    if (avgStatUpdate && avgStatUpdate.status == "SUCCESS") {
      fetchData();
    }
  }, [avgStatUpdate]);

  return (
    <>
      <Helmet>
        <title></title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth="xl">
          <Grid container>
            <Grid item md={12}>
              <Grid container spacing={3}>
                <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="District"
                    name="district"
                    onChange={handleDistrictChange}
                    select
                    SelectProps={{ native: true }}
                    value={currentDistrict.id}
                    variant="outlined"
                  >
                    {[{ id: 0, name: "" }, ...allDist].map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                {/* <Grid item md={2}>
                  <TextField
                    fullWidth
                    label="Division"
                    name="division"
                    onChange={handleDivisionChange}
                    select
                    SelectProps={{ native: true }}
                    value={currentDivision.id}
                    variant="outlined"
                  >
                    {[{ id: 0, name: "" }, ...allDivision].map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </TextField>
                </Grid> */}
              </Grid>
            </Grid>
            <Grid item md={6}>
              {formData && (
                <Formik
                  enableReinitialize
                  initialValues={{
                    ...formData,
                    submit: null,
                  }}
                  validationSchema={Yup.object().shape({
                    functional: Yup.number()
                      .integer()
                      .min(1, "Must be greater than 0")
                      .max(
                        formData["total"],
                        `Should be less than or equal to ${formData["total"]}`
                      )
                      .required("Cannot be empty"),
                    nonFunctional: Yup.number()
                      .integer()
                      .min(1, "Must be greater than 0")
                      .max(formData["total"] - formData["functional"])
                      .required("Cannot be empty"),
                    avgMotorRunning: Yup.number().min(1).max(20).required(),
                    avgElectricity: Yup.number().min(5).max(24).required(),
                    // avgWaterConsumption: Yup.number()
                    //   .min(70)
                    //   .max(120)
                    //   .required(),
                  })}
                  onSubmit={async (
                    values,
                    { setErrors, setStatus, setSubmitting }
                  ) => {
                    try {
                      //   await login(values.email, values.password);
                      let finalVal = {
                        ...values,
                        nonReporting:
                          Number(values["total"]) -
                          (Number(values["functional"]) +
                            Number(values["nonFunctional"])),
                        avgWaterConsumption:
                          Number(values["avgMotorRunning"]) * 3 * 60 * 60,
                      };
                      
                      dispatch(
                        updateAvgSchemeStats({
                          district: currentDistrict,
                          ...finalVal,
                        })
                      );
                      // if (mounted.current) {
                      //   setStatus({ success: true });
                      //   setSubmitting(false);
                      // }
                    } catch (err) {
                      console.error(err);
                      // if (mounted.current) {
                      //   setStatus({ success: false });
                      //   setErrors({ submit: "Username and Password did not match" });
                      //   setSubmitting(false);
                      // }
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      <Grid container spacing={2} sx={{ mt: 0 }}>
                        <Grid item md={12}>
                          <Typography color={"primary"}>
                            Total Schemes:
                            <span
                              style={{ fontWeight: "bold" }}
                            >
                              {" "}
                              {values["total"]} (No's)
                            </span>
                          </Typography>
                        </Grid>
                        {fields.map((fe, i) => {
                          let component = null;

                          if (
                            fe.fname == "nonReporting" ||
                            fe.name == "avgWaterConsumption"
                          ) {
                            component = (
                              <Grid item md={6} key={i}>
                                <TextField
                                  autoFocus
                                  error={Boolean(
                                    touched[fe.fname] && errors[fe.fname]
                                  )}
                                  fullWidth
                                  helperText={
                                    touched[fe.fname] && errors[fe.fname] ? (
                                      errors[fe.fname]
                                    ) : (
                                      <span style={{ color: priColor }}>
                                        {fe.note}
                                      </span>
                                    )
                                  }
                                  label={fe.name}
                                  // margin="normal"
                                  disabled={fe.disabled}
                                  name={fe.fname}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="text"
                                  value={
                                    formData["total"] -
                                    (Number(values["functional"]) +
                                      Number(values["nonFunctional"]))
                                  }
                                  variant="outlined"
                                />
                              </Grid>
                            );
                          } else if (fe.fname == "avgWaterConsumption") {
                            console.log(">>3", fe);
                            component = (
                              <Grid item md={6} key={i}>
                                <TextField
                                  // autoFocus
                                  // error={Boolean(
                                  //   touched[fe.fname] && errors[fe.fname]
                                  // )}
                                  fullWidth
                                  // helperText={
                                  //   touched[fe.fname] && errors[fe.fname] ? errors[fe.fname] : <span style={{color:priColor}}>{fe.note}</span>
                                  // }
                                  label={fe.name}
                                  // margin="normal"
                                  disabled={true}
                                  name={fe.fname}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="text"
                                  value={
                                    Number(values["avgMotorRunning"])
                                      ? Number(values["avgMotorRunning"]) *
                                        3 *
                                        60 *
                                        60
                                      : 0
                                  }
                                  variant="outlined"
                                />
                              </Grid>
                            );
                          } else {
                            component = (
                              <Grid item md={6} key={i}>
                                <TextField
                                  autoFocus
                                  error={Boolean(
                                    touched[fe.fname] && errors[fe.fname]
                                  )}
                                  fullWidth
                                  helperText={
                                    touched[fe.fname] && errors[fe.fname] ? (
                                      errors[fe.fname]
                                    ) : (
                                      <span style={{ color: priColor }}>
                                        {fe.note}
                                      </span>
                                    )
                                  }
                                  label={fe.name}
                                  name={fe.fname}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="text"
                                  value={values[fe.fname]}
                                  variant="outlined"
                                />
                              </Grid>
                            );
                          }
                          return (
                            <>
                              {component}
                              <Grid item md={6} alignContent="center">
                                <Typography
                                  sx={{ pt: 4, fontWeight: "bold" }}
                                  color={priColor}
                                >{`${
                                  fe.fname == "avgWaterConsumption"
                                    ? devimalFormat(
                                        actualValues[fe.fname] / 1000
                                      )
                                    : actualValues[fe.fname]
                                } ${fe.ext}`}</Typography>
                              </Grid>
                            </>
                          );
                        })}
                        <Grid item md={12} alignItems="center">
                          <Button variant="contained" type="submit">
                            Change Values
                          </Button>
                          <Button
                            variant="outlined"
                            sx={{ ml: 2 }}
                            onClick={() => {
                              // console.log({ district:currentDistrict, ...actualValues})
                              dispatch(
                                updateAvgSchemeStats({
                                  district: currentDistrict,
                                  ...actualValues,
                                })
                              );
                            }}
                          >
                            Reset to Actual Values
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              )}
            </Grid>
          </Grid>
        </Container>
        {avgStatUpdate && avgStatUpdate.status == "loading" && <Loader />}
        {avgSchemeStats && avgSchemeStats.status == "loading" && <Loader />}
        {operationalDataReport && operationalDataReport.status == "loading" && (
          <Loader />
        )}
      </Box>
    </>
  );
}

export default SchemeData;
