import { createSlice } from '@reduxjs/toolkit';
import { API } from '../config';
import { get, post } from './service';

const initialState = {
    district: {
        status: null,
        data: null
    },
    division: {
        status: null,
        data: null
    }
};

const slice = createSlice({
    name: 'locationData',
    initialState,
    reducers: {
        setDistrict(state, action) {
            state.district = action.payload
        },
        setDivision(state, action) {
            state.division = action.payload
        }
    }
});

export const { reducer } = slice;

export const getDistrict = () => async (dispatch) => {
    dispatch(slice.actions.setDistrict({ ...initialState.district, status: "loading" }));

    get(API.base_url + "phed/sitedata", { site_type: "districts" })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDistrict(response));
            // dispatch(slice.actions.setSummary({ district : Array.isArray(response.data)?response.data.length:0 }));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDistrict({ ...errResponse, status: "failed" }));
        });
};

export const getDivision = (distid) => async (dispatch) => {
    dispatch(slice.actions.setDivision({ ...initialState.division, status: "loading" }));

    get(API.base_url + "phed/sitedata", { site_type: "divisions", ref: "district", id: distid })
        .then((rawData) => rawData)
        .then(function (response) {
            response = response || {};
            dispatch(slice.actions.setDivision(response));
            // dispatch(slice.actions.setSummary({ district : Array.isArray(response.data)?response.data.length:0 }));
        })
        .catch(function (errResponse) {
            dispatch(slice.actions.setDivision({ ...errResponse, status: "failed" }));
        });
};

export default slice;