import { useRoutes } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import routes from "./routes";
import useSettings from './hooks/useSettings';
import { ThemeProvider } from "@mui/material";
import { createCustomTheme } from './theme';

const App = () => {
  const auth = useAuth();  
  const { settings } = useSettings();

  const content = useRoutes(routes);
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  return <ThemeProvider theme={theme}>{auth.isInitialized ? content : "Not initialised"}</ThemeProvider>;
};

export default App;
