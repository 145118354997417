import React from "react";
import PropTypes from "prop-types"
import {
    TextField,
    Button
} from "@mui/material"

function ImportCSV(props) {
    const [state, setState] = React.useState({
        csvFile: null
    })

    const handleFileSelect = (e) => {
        setState((prevState)=> {
            return{
                ...prevState,
                csvFile:e.target.files[0]
            }
        })
    }

    return (
        <div>
            <TextField
                type='file'
                variant='outlined'
                InputProps={{
                    endAdornment: (
                        <Button
                            disabled={state.csvFile ? false : true}
                            variant='contained'
                            color='primary'
                            onClick={() => {                                
                                props.handleCsvFileImport(state.csvFile)
                            }}>
                            SUBMIT
                        </Button>
                    )
                }}
                inputProps={{ accept: '.csv' }}
                label="Upload csv file"
                focused
                onChange={handleFileSelect}
            />
        </div>
    )
}

export default ImportCSV

ImportCSV.propType = {
    handleCsvFileImport: PropTypes.func
}

