import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Grid,
    Typography,
} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from "react";
import useSettings from "../../../hooks/useSettings";
import Placeholder from "./placeholder";
  
const menus = ["Dahboard", "Scheme Data", "Firmware", "User Management"];

const Udhd = () => {
    const [menuId, setMenuId] = useState(0);
    const { settings } = useSettings();

    const handleChange = function (e, indx) {
        setMenuId(indx);
    };
    
    return (<>
        <Box sx={{backgroundColor: "background.default", minHeight: "100%", py:1}}>
            <Container maxWidth={settings.compact ? "xxl" : false}>
                <Grid container justifyContent="space-between">
                    <Grid item width="100%">
                        <Typography color="textPrimary" variant="h5">
                            UDHD
                        </Typography><br />
                    </Grid>
                </Grid>
  
                <Tabs value={menuId} onChange={handleChange} aria-label="basic tabs example">
                    {menus.map((m, i) => {
                        return (<Tab
                            key={i}
                            label={m}
                            id={`simple-tab-${i}`}
                            aria-controls={`simple-tabpanel-${i}`} />)
                    })}
                </Tabs>
            
                <Box sx={{mt: 3, minHeight: "100vh"}}>
                    {menuId == 0 && <Placeholder />}
                    {menuId == 1 && <Placeholder />}
                    {menuId == 2 && <Placeholder />}
                    {menuId == 3 && <Placeholder />}
                </Box>
            </Container>
        </Box>
    </>);
};
  
export default Udhd;

/*
<ButtonGroup variant="contained" aria-label="outlined primary button group" >
                    {menus.map((m, i) => {
                        return (<Button
                            color="primary"
                            variant={menuId == i ? "contained" : ""}
                            onClick={() => (i > 3 ? null : setMenuId(i))}>{m}</Button>);
                    })}
                </ButtonGroup>
*/