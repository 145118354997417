import { Box, Button, Grid, Paper, Switch, Typography, Divider, IconButton, Tooltip, Alert } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { resetSchemes } from "../../../slices/siteInfo";
import { manualDeviceTrigger } from "../../../slices/manual_trigger";
import {importCsvFile} from "../../../slices/importCSV"
import LocationFilter from "../../../components/LocationFilter";
import { getRandomInt } from "../../../utils/helper";
import ImportCSV from "../../../components/ImportCSV";
import Loader from "../../../components/Loader/Loader";
import { Close } from "@material-ui/icons"
import { Snackbar } from "@material-ui/core"

function TriggerDeviceEvent(props) {
    const dispatch = useDispatch();
    const [schemeLocation, setSchemeLocation] = useState({});
    const { district, ward, lastSearchFilter } = useSelector((state) => state.siteinfo);
    const [allSchemes, setAllSchemes] = useState([]);
    const [checked, setChecked] = useState(true);
    const [saveTriggered, setSaveTriggered] = useState(false)
    const [showNotification, setShowNotification] = useState(false)
    const [isApiCalled,setIsApiCalled] = useState(false)
    const [isTriggerSwitch,setIsTriggerSwitch] = useState(false)
    const { status, data, error } = useSelector((state) => state.importCsv.csvFileData)


    const onChangeLocation = (data) => {
        const keys = Object.keys(data);
        let allReset = true;
        for (let i = 0; i < keys.length; i++) {
            allReset = allReset && !data[keys[i]];
        }

        if (allReset) {
            setAllSchemes([]);
            dispatch(resetSchemes());
        }
        setSchemeLocation(data);
        setSaveTriggered(false)
    };

    useEffect(() => {
        if (ward && ward.status == 'SUCCESS') {
            setAllSchemes(ward.data)
        }
    }, [ward])

    useEffect(() => {
        if (status === "success" && isApiCalled) {
            setShowNotification(true)

            const timer = setTimeout(() => {
                setShowNotification(false);
            }, 6000);

            return () => clearTimeout(timer);
        }
    }, [status])

    const handleChange = (type, data, checked) => {
        let allSchemesTemp = [...allSchemes]

        for(let i=0; i<allSchemesTemp.length; i++){
            if(allSchemesTemp[i]['id'] == data['id']){
                let triggerData = {}
                if(allSchemesTemp[i]['manually_trigger']){
                    triggerData = {...allSchemesTemp[i]['manually_trigger']}
                }

                triggerData = {...triggerData, [type]: checked}

                if(checked){
                    if(type == 'motor'){
                        triggerData['electricity'] = true
                    }
                }else{
                    if(type == 'electricity'){
                        triggerData['motor'] = false
                    }
                }

                allSchemesTemp[i] = {
                    ...allSchemesTemp[i],
                    manually_trigger: {
                        ...triggerData
                    }
                }
            }
        }
        setAllSchemes(allSchemesTemp)
    };

    const updateManualTrigger = () => {
        const dt = new Date()
        let allSchemesCopy = allSchemes.filter((e) => e.manually_trigger && (e.manually_trigger.motor || e.manually_trigger.electricity))
        allSchemesCopy = allSchemesCopy.map((e) => {
            if(e.manually_trigger){
                let devData =  {
                    "p": "1",
                    "ttype": "m",
                    "l": "1",
                    "i": "0",
                    "j": "0",
                    "pt": "0",
                    "g": `${getRandomInt(3,20)}`,
                    "m": e.manually_trigger.motor ? "4" : "0",
                    "z": "1",
                    "d": e.fw_device_id ? `${e.fw_device_id}` : "null",
                    "didMonth": `${dt.getFullYear()}-${dt.getMonth()}-${e.smsid}`
                }
                if(!e.fw_device_id){
                    devData["smsid"] = e.smsid
                }
                return devData
            }
            return null
        })

        dispatch(manualDeviceTrigger(allSchemesCopy))
        setSaveTriggered(true)
    }

    const handleCsvFileImport = async(data) => {
        try {
            await dispatch(importCsvFile(data))
            setIsApiCalled(true)
        } catch (error) {
            console.log("Error while api call", error)
        }
    }

    return <>
        <Box sx={{ p: 3,display:"flex",flexDirection:"column",gap:"14px" }}>
            <Typography sx={{
                display:"flex",
                gap:"4px",
                alignItems:"center",
                fontFamily:"Lato",
                fontStyle:"normal",
                fontWeight:500,
                color:"white"
            }}>
                <span>
                    Individual trigger
                </span>
                <Switch
                   onChange={()=> {
                        setIsTriggerSwitch(!isTriggerSwitch)
                    }}
                />
                <span>File Upload</span>
            </Typography>
            {
                isTriggerSwitch && (
                    <Typography sx={{
                        display:"flex",
                        flexDirection:"column",
                        gap:"10px"
                    }}>
                        <ImportCSV
                            handleCsvFileImport={handleCsvFileImport}
                        />
                        {
                            status === "success" && data.status && data.data.successMessages && data.data.successMessages.length > 0 && (
                                <Paper style={{
                                    textAlign:"center",
                                    padding:"10px",
                                    backgroundColor:"green"
                                }}>
                                    {
                                        Array.isArray(data.data.successMessages) && 
                                        data.data.successMessages.map((item,index)=> {
                                            return(
                                                <li key={index}>
                                                    {item}
                                                </li>
                                            )
                                        })
                                    }
                                    
                                </Paper>
                            )
                        }
                        {
                            status === "success" && data.status && data.data.errorMessages && data.data.errorMessages.length > 0 && (
                                <Paper style={{
                                    padding:"10px",
                                    textAlign:"center",
                                    backgroundColor:"red"
                                }}>
                                    {
                                        Array.isArray(data.data.errorMessages) && 
                                        data.data.errorMessages.map((item,index)=> {
                                            return(
                                                <li key={index}>
                                                    {item}
                                                </li>
                                            )
                                        })
                                    }
                                </Paper>
                            )
                        }
                    </Typography>
                )
            }
            {
                !isTriggerSwitch && (
                    <LocationFilter
                        onChange={onChangeLocation}
                        initialValues={lastSearchFilter}
                        schemes={allSchemes}
                        downloadExcel={true}
                    />
                )
            }
            <Box mt={3}>
                <Paper>
                    <Grid container direction={'column'}>
                        {allSchemes.map((wd, key) => {
                            return <Grid item key={key} container display={'flex'} borderBottom={'1px solid #CCC'} alignItems={'center'}>
                                <Grid item md={2} display={'flex'} justifyContent={'center'}>
                                    Ward {wd.name}
                                </Grid>
                                <Grid item md={2}  >
                                    <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item>
                                            <Typography fontWeight={'bold'}>Electricity</Typography>
                                        </Grid>
                                        <Grid item>
                                            Off <Switch
                                                checked={wd["manually_trigger"] && wd["manually_trigger"]["electricity"]?wd["manually_trigger"]["electricity"]:false}
                                                onChange={(event) => handleChange('electricity', wd, event.target.checked)}
                                            /> On
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={2}>
                                    <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
                                        <Grid item>
                                            <Typography fontWeight={'bold'}>Motor</Typography>
                                        </Grid>
                                        <Grid item>
                                            Off <Switch
                                               checked={wd["manually_trigger"] && wd["manually_trigger"]["motor"]?wd["manually_trigger"]["motor"]:false}
                                                onChange={(event) => handleChange('motor', wd, event.target.checked)}
                                            /> On
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                    {allSchemes.length ?
                        <Box sx={{
                            padding: 3,
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Button disabled={saveTriggered} variant="contained" onClick={updateManualTrigger}>Save</Button>
                    </Box>:null}
                </Paper>
            </Box>
        </Box>
        {
            showNotification && (
                <Snackbar
                    open={showNotification}
                    autoHideDuration={3000}
                    message={
                        <Alert security='success' >
                            {data && data.message}
                        </Alert>
                    }
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    action={
                        <React.Fragment>
                            <IconButton onClick={() => { setShowNotification(false) }}>
                                <Tooltip title="Close" arrow placement="bottom">
                                    <Close />
                                </Tooltip>
                            </IconButton>
                        </React.Fragment>
                    }
                />
            )
        }
        {
            status && status === "loading" && (
                <Loader />
            )
        }
    </>
}

export default TriggerDeviceEvent