import { Helmet } from "react-helmet-async";
// import gtm from "../../lib/gtm";
import login_screen_image from "../images/login_screen_image_new.png";
import BrandBanner from "../components/BrandBanner";
import { DASHBOARD_URL, POWERED_BY, title } from "../constants";
import { Box, Button, Card, CardContent, Container, FormControl, FormControlLabel, Grid, Link, Radio, RadioGroup, Typography } from "@mui/material";
import AuthBanner from "../components/AuthBanner";
import { priColor } from "../constants";
import { useState } from "react";

const LandingPage = () => {

  const [disabledDashboard, setDisabledDashboard] = useState(false);
  const [selectedDashboard, setSelectedDashboard] = useState("");
  return (
    <>
      <Helmet>
        <title>{title.label2}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          height:"100vh",
          flexDirection: "column",
        }}
      >
        <AuthBanner />
        <Container sx={{ py: "100px" }}>
          {/* <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              Note * : <font color="orange">Backend database structure upgrade in progress (till 24th Jan 2022). Some features may not work.</font>
            </Grid>
          </Grid> */}

          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                container spacing={3}
                style={{ display: "flex", display:"row", justifyContent: "space-between" }}
              >
                <Grid item md="7" sm="12">
                  <img
                    style={{ borderRadius: 10 }}
                    width="100%"
                    src={login_screen_image}
                  />
                </Grid>
                <Grid item md="5" 
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Box>
                    <Typography variant="h5">Welcome</Typography> 
                    <Typography variant="h5">AYNIC / HORIZONS User</Typography>
                    </Box>
                    {/* <div style={{ display: "flex", flexDirection: "row" }}>
                      <img src={logo} width={60} />
                      <div style={{ display: "flex", flexDirection: "column-reverse", marginLeft: 10 }}>
                        <Typography color="textSecondary" variant="h6" >Powered By <span style={{color:"#727cf5"}}>{title.label1}</span></Typography>
                        <Typography color="textPrimary" variant="h5">
                          {title.label2}
                        </Typography>
                      </div>
                    </div> */}
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      mt: 3,
                    }}
                  >
                     <div className="chooseDashboard">
                      <Typography variant="h6">
                        <Box sx={{ fontWeight: "bold" }} className="title">
                          Choose your Dashboard
                        </Box>
                      </Typography>
                      <FormControl
                        style={{ marginTop: 5 }}
                        onChange={(e) => {
                          setSelectedDashboard(e.target.defaultValue);
                        }}
                      >
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          {DASHBOARD_URL.map((dbe, key) => {
                            return (
                              <FormControlLabel
                                key={key}
                                value={dbe.url}
                                control={<Radio style={{ color: priColor }} />}
                                label={dbe.name}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: priColor,
                          width: "50%",
                          borderRadius: 16,
                          fontWeight: "bold",
                          marginTop:20
                        }}
                        onClick={() => {
                          const selectedObj = DASHBOARD_URL.filter(
                            (e) => e.url == selectedDashboard
                          );
                          if (selectedObj.length && !selectedObj[0]["enable"])
                            setDisabledDashboard(true);
                          else window.location.href = selectedObj[0]["url"]
                        }}
                      >
                        Proceed
                      </Button>
                    </div>
                    {/* <Grid container marginTop={1} flexDirection="column">
                      <Grid item>
                        Do not have account? <Link href={"/authentication/signup"}>Sign Up</Link>
                      </Grid>
                      <Grid item>
                        Forgot Passowrd? <Link href={"/authentication/reset-password"}>Reset now</Link>
                      </Grid>
                    </Grid> */}
                    
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <Typography color={"white"} variant="h6">
              Powered by <b>{POWERED_BY} &#174;</b>
            </Typography>
          </div>
        </Container>
        <Container maxWidth={"xxl"}>
          <Box sx={{
            borderTop:"1px solid grey",
            display: "flex",
            flexDirection: "row"
          }}>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              marginRight:5,
              marginTop: 5
            }}>
              <Typography color={"white"}> AYNIC Private Limited</Typography>
              <Typography color={"white"}>Plot no. 23, Rao & Raju Colony, Road No. 2,</Typography>
              <Typography color={"white"}>Banjara Hills, Hyderabad.</Typography>
              <Typography color={"white"}>Telangana – 500034. </Typography>
              <Typography color={"white"}>Ph.:9100901155 | sales@aynic.in, www.aynic.in</Typography>
            </Box>
            <Box sx={{
              // borderLeft:"1px solid red",
              marginLeft:5,
              display: "flex",
              flexDirection: "column",
              marginTop: 5
            }}>          
              <Typography color={"white"}> Horizons Ingenious Private Limited</Typography>
              <Typography color={"white"}>(Formerly Nandikonda Infratech Pvt. Ltd.)</Typography>
              <Typography color={"white"}>Office no. 80, Patliputra colony, near Notredame school, </Typography>
              <Typography color={"white"}>Patna, Bihar – 800013 | +91 70500 11222</Typography>
              <Typography color={"white"}>info.bih@horizons-ingenious.com</Typography>
            </Box>
          </Box>
          <Box sx={{display:"flex", flexDirection:"row-reverse"}}>
          <a
              href="/authentication/login"
              className="adminLink"
            >
              Admin
            </a>
            {/* <Typography color={"white"} onClick={"/admin"} style={{cursor:"pointer"}}>Admin</Typography> */}
            </Box>
        </Container>
      </Box>
    </>
  );
};

export default LandingPage;
