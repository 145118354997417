import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
const AdminGuard = ({ children }) => {
    const usertype = localStorage.getItem("USER_TYPE")
    if (usertype && usertype.includes("APP_SUPER_ADMIN")) {
        return <>{children}</>;
    }
    else {
        return <Navigate to="/authentication/login" />;
    }
};

AdminGuard.propTypes = {
    children: PropTypes.node
};

export default AdminGuard;
