import { createSlice } from "@reduxjs/toolkit";
import { API } from "../config";
import { get, post } from "./service";

const initialState = {
  operationalDataReport: {
    status: null,
    data: null,
  },
  avgSchemeStats: {
    status: null,
    data: null,
  },
  avgStatUpdate: {
    status: null,
    data: null,
  },
};

const slice = createSlice({
  name: "OperationalData",
  initialState,
  reducers: {
    setOperationalData(state, action) {
      state.operationalDataReport = action.payload;
    },
    setAvgSchemeStats(state, action) {
      state.avgSchemeStats = action.payload;
    },
    setAvgSchemeStatUpdate(state, action) {
      state.avgStatUpdate = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getOperationalData = (location, dateRange) => async (dispatch) => {
  dispatch(
    slice.actions.setOperationalData({
      ...initialState.operationalDataReport,
      status: "loading",
    })
  );
  // console.log(">>getShcemeOperationalData", did, divId)
  post(API.base_url + "phed/device_operational_status", {
    ...location,
    dateRange,
  })
    .then((rawData) => rawData)
    .then(function (response) {
      response = response || {};
      dispatch(slice.actions.setOperationalData(response));
    })
    .catch(function (errResponse) {
      dispatch(
        slice.actions.setOperationalData({ ...errResponse, status: "failed" })
      );
    });
};

export const getAvgSchemeStats =
  (did, divid, dateRange) => async (dispatch) => {
    dispatch(
      slice.actions.setAvgSchemeStats({
        ...initialState.avgSchemeStats,
        status: "loading",
      })
    );

    get(API.base_url + "phed/homepage_device_stats", {
      did,
      divid,
      from: dateRange[0],
      to: dateRange[1],
    })
      .then((rawData) => rawData)
      .then(function (response) {
        response = response || {};
        dispatch(slice.actions.setAvgSchemeStats(response));
      })
      .catch(function (errResponse) {
        dispatch(
          slice.actions.setAvgSchemeStats({ ...errResponse, status: "failed" })
        );
      });
  };

//update-temp-stats
export const updateAvgSchemeStats = (data) => async (dispatch) => {
  dispatch(
    slice.actions.setAvgSchemeStatUpdate({
      ...initialState.avgStatUpdate,
      status: "loading",
    })
  );

  post(API.base_url + "phed/update-temp-stats", { ...data })
    .then((rawData) => rawData)
    .then(function (response) {
      response = response || {};
      dispatch(slice.actions.setAvgSchemeStatUpdate(response));
    })
    .catch(function (errResponse) {
      dispatch(
        slice.actions.setAvgSchemeStatUpdate({
          ...errResponse,
          status: "failed",
        })
      );
    });
};
