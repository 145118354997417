import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
    Box,
    Divider,
    Drawer,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import useAuth from "../../hooks/useAuth";
import ChartPieIcon from "../../icons/ChartPie";
import HomeIcon from "../../icons/Home";
import UserIcon from "../../icons/User";
import NavSection from "../../components/NavSection";
import Scrollbar from "../../components/Scrollbar";
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';

// import {
//   Dashboard,
//   LocalFireDepartmentSharp,
//   DeviceHub,
//   PhonelinkSetup
// } from "@mui/icons-material";
// import BugReportIcon from '@mui/icons-material/BugReport';
// import BuildIcon from '@mui/icons-material/Build';
// const user = {"email":"shashi@gmail.com","id":"shashi@gmail.com","name":"Test FW","mobile_number":"9999247862","dob":"01/01/1990","password":"8622f0f69c91819119a8acf60a248d7b36fdb7ccf857ba8f85cf7f2767ff8265","organization":"test","userType":"GERON_FIRMWARE_OPERATOR","userStatus":"VERIFIED","metadata":{"otherUserInfo":""},"_rid":"posYAOfrBuAgAAAAAAAAAA==","_self":"dbs/posYAA==/colls/posYAOfrBuA=/docs/posYAOfrBuAgAAAAAAAAAA==/","_etag":"\"00008c04-0000-2000-0000-63c151f20000\"","_attachments":"attachments/","otp":null,"_ts":1673613810}

const DashboardSidebar = (props) => {
    const { onMobileClose, openMobile } = props;
    const location = useLocation();
    const { user } = useAuth();
    // const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
    const [sections, setSections] = useState([]);

    useEffect(() => {
        let sec = [
            {
                "title": "General",
                "items": [
                    {
                        "title": "PHED",
                        "path": "/dashboard/phed",
                        "icon": <PhonelinkSetupIcon fontSize="small" />
                    },
                    {
                        "title": "PRD",
                        "path": "/dashboard/prd",
                        "icon": <PhonelinkSetupIcon fontSize="small" />
                    },
                    // {
                    //     "title": "UDHD",
                    //     "path": "/dashboard/udhd",
                    //     "icon": <PhonelinkSetupIcon fontSize="small" />
                    // },
                    {
                        "title": "Mobile App",
                        "path": "/dashboard/mobile-app",
                        "icon": <PhonelinkSetupIcon fontSize="small" />
                    }
                ]
            }
        ];

        setSections(sec);
    }, [user]);

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, [location.pathname]);

    const content = (
        <Box sx={{display: "flex", flexDirection: "column", height: "100%"}}>
            <Scrollbar options={{ suppressScrollX: true }}>
            <Divider />
            <Box sx={{ p: 2 }}>
                {sections.map((section) => (
                    <NavSection
                    key={section.title}
                    pathname={location.pathname}
                    sx={{
                        "& + &": {
                        mt: 3,
                        },
                    }}
                    {...section}
                    />
                ))}
            </Box>
        </Scrollbar>
        </Box>
    );

    return (<Drawer
        anchor="left"
        open PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant="permanent">
        {content}
      </Drawer>
    );
};

DashboardSidebar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

export default DashboardSidebar;