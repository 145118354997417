import {
  Box,
  Container,
  Typography,
} from "@mui/material";
import logo from "../images/logo_white.png";

const platformIcons = {
  Amplify: "/static/icons/amplify.svg",
  Auth0: "/static/icons/auth0.svg",
  Firebase: "/static/icons/firebase.svg",
  JWT: "/static/icons/jwt.svg",
};

const AuthBanner = () => (
  <Box
    sx={{
      borderBottom: 1,
      borderColor: "divider",
      py: 2,
    }}
  >
    <Container maxWidth="md">
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          width={"9%"}
          src={logo}
          style={{
            marginRight: 10,
          }}
        />
        <Box
          sx={{
            alignItems: "bottom",
            display: "flex",
            flexDirection: "column",
            ml: 2,
            "& > img": {
              height: 30,
              mx: 2,
            },
          }}
        >
          <Typography color="textPrimary" fontWeight={"bold"} variant="h5">HAR GHAR NAL JAL YOJNA</Typography>
            {/* <br />Public Health Engineering Department */}
            <Typography color="textPrimary" fontWeight={"bold"} variant="h5"> Government of Bihar</Typography>
        </Box>
      </Box>
    </Container>
  </Box>
);

export default AuthBanner;
