import {createSlice} from "@reduxjs/toolkit"
import { API } from "../config"
import {post,get,filepost} from "./service"

const initialState = {
    csvFileData:{
        status:null,
        data:null,
        error:null
    }
}

const slice = createSlice({
    name:'csvFileImport',
    initialState,
    reducers:{
        setCsvImport(state,action){
            state.csvFileData = action.payload
        }
    }
})

export const {reducer} = slice;
//https://hidashboardapi.azurewebsites.net/api/phed/maintenance/manual_device_trigger
export const importCsvFile=(data)=> async(dispatch)=> {
    dispatch(slice.actions.setCsvImport({...initialState.csvFileData,status:"loading"}));
    filepost(API.base_url+"phed/maintenance/manual_device_trigger",{device_file:data})
    .then((result)=> result)
    .then(function(response){
        response = response || {}
        dispatch(slice.actions.setCsvImport({...initialState.csvFileData,status:"success",data:response}))
    })
    .catch(function(errRes){
        dispatch(slice.actions.setCsvImport({...initialState.csvFileData,status:"failed",error:errRes}))
    })
}

export default slice;