import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from "react";
import useSettings from "../../../hooks/useSettings";
import Placeholder from "./placeholder";
import RegisteredDeviceList from "./RegisteredDeviceList";
import SchemeData from "./SchemeData/SchemeData";
import TriggerDeviceEvent from "./TriggerDeviceEvent";

const menus = ["Dahboard", "Scheme Data", "Firmware", "User Management", "Trigger Device Events"];

const Phed = function () {
    const [menuId, setMenuId] = useState(0);
    const {settings} = useSettings();

    const handleChange = function (e, indx) {
        setMenuId(indx);
    };
  
    return (<>
        <Box sx={{backgroundColor: "background.default", minHeight: "100%", py:1}}>
            <Container maxWidth={settings.compact ? "xxl" : false}>
                <Grid container justifyContent="space-between">
                    <Grid item width="100%">
                        <Typography color="textPrimary" variant="h5">
                            PHED
                        </Typography><br />
                    </Grid>
                </Grid>

                <Tabs value={menuId} onChange={handleChange} aria-label="basic tabs example">
                    {menus.map((m, i) => {
                        return (<Tab
                            key={i}
                            label={m}
                            id={`simple-tab-${i}`}
                            aria-controls={`simple-tabpanel-${i}`} />)
                    })}
                </Tabs>
            
                <Box sx={{mt: 3, minHeight: "100vh" }}>
                    {menuId == 0 && <Placeholder />}
                    {menuId == 1 && <SchemeData />}
                    {menuId == 2 && <RegisteredDeviceList />}
                    {menuId == 3 && <Placeholder />}
                    {menuId == 4 && <TriggerDeviceEvent />}
                </Box>
            </Container>
        </Box>
    </>);
}

export default Phed;

/*
<ButtonGroup variant="contained" aria-label="outlined primary button group">
    {menus.map((m, i) => {
        return (<Button
            key={i}
            color="primary"
            variant={menuId == i ? "contained" : ""}
            onClick={() => (i > menus.length ? null : setMenuId(i))}>{m}</Button>);
    })}
</ButtonGroup>
*/