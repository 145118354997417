import {
    Box,
    Button,
    ButtonGroup,
    Container,
    Grid,
    Typography,
  } from "@mui/material";
  import { useState } from "react";
  import useSettings from "../../../hooks/useSettings";
  import Placeholder from "./placeholder";
  
  // const menus = ["Dahboard", "Scheme Data", "Firmware", "User Management"];
  function MobileApp() {
    const [menuId, setMenuId] = useState(0);
    const { settings } = useSettings();
    return (
      <>
        <Box
          sx={{
            backgroundColor: "background.default",
            minHeight: "100vh",
            py:1,
          }}
        >
          <Container maxWidth={settings.compact ? "xxl" : false}>
            <Grid container justifyContent="space-between">
              <Grid item width="100%">
                <Typography color="textPrimary" variant="h5">
                  Mobile App
                </Typography><br />
              </Grid>
            </Grid>
  
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              {/* {menus.map((m, i) => {
                return (
                  <Button
                    color="primary"
                    variant={menuId == i ? "contained" : ""}
                    onClick={() => (i > 3 ? null : setMenuId(i))}
                  >
                    {m}
                  </Button>
                );
              })} */}
            </ButtonGroup>
            <Box sx={{ mt: 3 }}>
              {/* {menuId == 0 && <Placeholder />}
              {menuId == 1 && <Placeholder />}
              {menuId == 2 && <Placeholder />}
              {menuId == 3 && <Placeholder />} */}
              <Placeholder />
            </Box>
          </Container>
        </Box>
      </>
    );
  }
  
  export default MobileApp;
  