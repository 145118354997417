// import { Navigate } from "react-router-dom";
import AdminGuard from "./authAPI/guards/AdminGuard";
import GuestGuard from "./authAPI/guards/GuestGuard";
import LoginCheck from "./authAPI/guards/LoginCheck";
import DashboardLanding from "./pages/dashboard/DashboardLanding";
import MobileApp from "./pages/dashboard/mobileapp";
import Phed from "./pages/dashboard/phed";
import Prd from "./pages/dashboard/prd";
import Udhd from "./pages/dashboard/udhd";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/login";
import ChangePassword from "./pages/profile/ChangePassword";
import Profile from "./pages/profile/Profile";

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: <LoginCheck><Login /></LoginCheck>,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLanding />,
    children: [
      {
        path: "phed",
        element: <AdminGuard>
          <Phed />
        </AdminGuard>,
      },
      {
        path: "prd",
        element: <AdminGuard>
          <Prd />
        </AdminGuard>,
      },
      {
        path: "udhd",
        element: <AdminGuard>
          <Udhd />
        </AdminGuard>,
      },
      {
        path: "mobile-app",
        element: <AdminGuard>
          <MobileApp />
        </AdminGuard>,
      },
      ,
      {
        path: "change-password",
        element: <AdminGuard>
          <ChangePassword />
        </AdminGuard>,
      }
      ,
      {
        path: "profile",
        element: <AdminGuard>
          <Profile />
        </AdminGuard>,
      },
    ],
  },
  {
    path: "*",
    element: <GuestGuard><LandingPage /></GuestGuard>,
  },
];

export default routes;
