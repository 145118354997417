import { combineReducers } from '@reduxjs/toolkit';
import { reducer as siteInfo } from '../slices/siteInfo';
import { reducer as firmwareRegistration } from "../slices/firmwareRegistration"
import { reducer as location } from "../slices/location"
import { reducer as schemeReport } from "../slices/schemeReport"
import { reducer as manualTrigger } from "../slices/manual_trigger"
import { reducer as schemesDeviceOperational } from "../slices/schemesDeviceOperational"
import { reducer as deviceStats } from '../slices/deviceStats'
import {reducer as importCsv} from "../slices/importCSV"

const rootReducer = combineReducers({
  firmwareRegistration,
  location,
  schemeReport,
  manualTrigger,
  siteinfo: siteInfo,
  schemesDeviceOperational,
  deviceStats,
  importCsv
});

export default rootReducer;
