import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Card,
  CardContent,
  Container,
  Button,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import AuthBanner from "../../components/AuthBanner";
// import useAuth from "../../hooks/useAuth";
// import gtm from "../../lib/gtm";
import login_screen_image from "../../images/login_screen_image.png";
import { POWERED_BY, title } from "../../constants";
// import { useSelector } from "../../store";
import BrandBanner from "../../components/BrandBanner";
import LoginUI from "./LoginUI";

const Login = () => {
  //   const { platform } = useAuth();
  //   const data = useSelector((state) => state);

  //   useEffect(() => {
  //     if(localStorage.getItem("accessToken"))
  //         window.location="/dashboard"
  //   }, []);

  return (
    <>
      <Box style={{
        // backgroundImage: 'url("/bg_image.jpg")',
        backgroundSize: "100%",
        height: "100vh"
      }}
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <AuthBanner /> */}
        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignItems="center"
          marginTop={20}
        >
          <Card sx={{
                boxShadow: "-2px -2px 9px 5px #ccc;",}}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid
                container
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* <Grid md="7" sm="12">
                  <img
                    style={{ borderRadius: 10 }}
                    width="100%"
                    src={login_screen_image}
                  />
                </Grid> */}
                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <BrandBanner />
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      mt: 3,
                    }}
                  >
                    <LoginUI />
                    <Grid container marginTop={1} flexDirection="column">
                      {/* <Grid item>
                        Do not have account? <Link href={"/authentication/signup"}>Sign Up</Link>
                      </Grid> */}
                      <Grid item>
                        Forgot Passowrd? <Link href={"/authentication/signup"}>Reset now</Link>
                      </Grid>
                    </Grid>

                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <span>
              Powered by <b>{POWERED_BY} &#174;</b>
            </span>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Login;
