import PropTypes from "prop-types"
import React, { Fragment } from "react"
import "./loader.css"
const Loader = (props) => {
    return (
        <Fragment>
            <div className="newLoading">
                {props.text ? (
                    <span
                        style={{
                            position: "fixed",
                            marginLeft: 25,
                            backgroundColor: "#cccccc8f",
                            marginTop: -12,
                            borderRadius: 5,
                            fontWeight: "bold",
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}>
                        {props.text}
                    </span>
                ) : null}
            </div>
        </Fragment>
    )
}

Loader.propTypes = {
    text: PropTypes.string,
}

export default Loader