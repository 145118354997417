import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Typography,
  Link,
  TableHead,
  Button,
  Modal,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ButtonGroup,
  Tooltip,
} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link as RouterLink } from "react-router-dom";
import ChevronRightIcon from "../../../icons/ChevronRight";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDeviceData,
  getRegisteredDevices,
} from "../../../slices/firmwareRegistration";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { getDateTime } from "../../../utils/helper";

const style = {
  position: "absolute",
  color:"#FFF",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #CCC",
  boxShadow: 24,
  p: 4,
};
const initialCheckedOption = {
  reg: false,
  telemetry: false,
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function RegisteredDeviceList() {
  const [status, setStatus] = React.useState("all");
  const [statusIndx, setStatusIndx] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRegDeviceData] = React.useState([]);
  const [allDeviceData, setAllDeviceData] = React.useState([]);
  const {
    firmwareData: firmwareDataApiResponse,
    deleteDeviceData: deleteDeviceDataApiResponse,
  } = useSelector((state) => state.firmwareRegistration);
  const [openModal, setOpenModal] = React.useState(false);
  const [currentDevice, setCurrentDevice] = React.useState(null);
  const [viewDetailModalOpen, setViewDetailModalOpen] = useState(false)
  const [viewDetailWard, setViewDetailWard] = useState(null)
  // const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setCheckedOptions(initialCheckedOption);
    setOpenModal(false);
  };
  const dispatch = useDispatch();
  const [checkedOptions, setCheckedOptions] = useState(initialCheckedOption);

  useEffect(() => {
    dispatch(getRegisteredDevices());
  }, []);

  useEffect(() => {
    if (firmwareDataApiResponse && firmwareDataApiResponse.data && Array.isArray(firmwareDataApiResponse.data)) {
      const rws = firmwareDataApiResponse.data.map((e, i) => {
        const dt = getDateTime(e._ts);
        return {
          slno: i + 1,
          ...e,
          obj: e,
          created_datetime: `${dt["date"]} ${dt["time"]}`,
        };
      });
      setAllDeviceData(rws);
      setRegDeviceData(rws);
      if (rws.length < rowsPerPage) {
        setRowsPerPage(rws.length + 1);
      }
    }
  }, [firmwareDataApiResponse]);

  const columns = [
    {
      field: "slno",
      headerName: "Sl No",
      width: 20,
      sortable: false,
      headerClassName: "scheme-data-table",
    },
    {
      field: "barcode",
      headerName: "Barcode",
      width: 80,
    },
    {
      field: "imei",
      headerName: "IMEI",
      width: 160,
    },
    {
      field: "deviceId",
      headerName: "Software Device Id",
      width: 130,
    },
    {
      field: "simNumber",
      headerName: "SIM Number",
      width: 190,
    },
    {
      field: "carrierName",
      headerName: "Carrier Name",
      width: 100,
    },

    {
      field: "user_name",
      headerName: "User Name",
      width: 130,
    },
    {
      field: "mobile_number",
      headerName: "Mobile Number",
      width: 130,
    },
    {
      field: "created_datetime",
      headerName: "Date Time",
      width: 140,
    },
    {
      field: "certificate_path",
      headerName: "Certificate",
      width: 130,
      renderCell: (params) => {
        const data = params["formattedValue"];
        return data ? (
          <Link target="_blank" href={data}>
            Download
          </Link>
        ) : (
          "Not Found"
        );
      },
    },
    {
      field: "obj",
      sortable: false,
      disableExport: true,
      headerName: "Action",
      width: 180,
      sortable: false,
      renderCell: (params) => {
        if (params["formattedValue"]["assignedWard"] == null)
          return (<>
            <Button
              disabled={params["formattedValue"]["assignedWard"]}
              onClick={() => handleClick(params["formattedValue"])}
            >
              Delete
            </Button>
            {/* <Button onClick={() => {
              setViewDetailModalOpen(true)
              setViewDetailWard(params["formattedValue"])
            }}>View detail</Button> */}
            </>
          );
        else
          return (<>
            <Tooltip title="Already assigned to ward">
              <Typography variant="p" color={"red"}>
                Cannot delete
              </Typography>
            </Tooltip>
            
            <Button marginLeft={2} onClick={() => {
              setViewDetailModalOpen(true)
              setViewDetailWard(params["formattedValue"])
            }}>View</Button>
            </>
          );
      },
    },
  ];

  // useEffect(() => {
  //   setRegDeviceData(allDeviceData.filter((e) => 'barcode' in e && 'carrierName' in e && 'certificate_path' in e && 'simNumber' in e ))
  // }, [allDeviceData])

  useEffect(() => {
    let rowsTemp = [];
    if (status == "pass") {
      rowsTemp = allDeviceData.filter(
        (e) =>
          "barcode" in e &&
          "carrierName" in e &&
          "certificate_path" in e &&
          "simNumber" in e
      );
    } else if (status == "fail") {
      rowsTemp = allDeviceData.filter(
        (e) =>
          !( 
            "barcode" in e &&
            "carrierName" in e &&
            "certificate_path" in e &&
            "simNumber" in e
          )
      );
    } else {
      rowsTemp = allDeviceData;
    }

    setRegDeviceData(rowsTemp);
  }, [status]);

  const handleClick = (data) => {
    setCheckedOptions(initialCheckedOption);
    setCurrentDevice(data);
    setOpenModal(true);
  };

  const handleSubmit = () => {
    setCheckedOptions(initialCheckedOption);
    dispatch(
      deleteDeviceData({
        deleteFlag: { ...checkedOptions },
        device: currentDevice,
      })
    );
    handleCloseModal();
  };

  useEffect(() => {
    if (
      deleteDeviceDataApiResponse &&
      deleteDeviceDataApiResponse.status == "SUCCESS"
    ) {
      dispatch(getRegisteredDevices());
    }
  }, [deleteDeviceDataApiResponse]);


  const handleChange = function (e, index) {
    const statusDict = {
      "0": "all",
      "1": "pass",
      "2": "fail",
    };
    
    setStatus(statusDict[index]);
    setStatusIndx(index);
  }

  return (
    <>
      <Helmet>
        <title>Maintenance | Har Ghar Nal Jal Yojna</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Box>
        <Tabs value={statusIndx} onChange={handleChange} aria-label="basic tabs example">
          <Tab label={"All"}  id={`simple-tab-0`} aria-controls={`simple-tabpanel-0`} />
          <Tab label={"Pass"} id={`simple-tab-1`} aria-controls={`simple-tabpanel-1`} />
          <Tab label={"Fail"} id={`simple-tab-2`} aria-controls={`simple-tabpanel-2`} />
        </Tabs>

          {/* <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              variant={status == "all" ? "contained" : ""}
              onClick={() => setStatus("all")}
            >
              All
            </Button>
            <Button
              variant={status == "pass" ? "contained" : ""}
              onClick={() => setStatus("pass")}
            >
              Pass
            </Button>
            <Button
              variant={status == "fail" ? "contained" : ""}
              onClick={() => setStatus("fail")}
            >
              Fail
            </Button> 
          </ButtonGroup> */}

          <div style={{ height: 650, width: "100%", marginTop: 10 }}>
            <DataGrid
              components={{
                Toolbar: CustomToolbar,
              }}
              loading={!rows.length}
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        </Box>
      </Box>
      <Modal
        open={viewDetailModalOpen}
        onClose={() => {
          setViewDetailModalOpen(false)
          setViewDetailWard(null)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {viewDetailWard && viewDetailWard.assignedWard ? <>
            <Typography>Name: {viewDetailWard.assignedWard.name}</Typography>
            <Typography>District: {viewDetailWard.assignedWard.districtName}</Typography>
            <Typography>Division: {viewDetailWard.assignedWard.divisionName}</Typography>
            <Typography>Block: {viewDetailWard.assignedWard.blockName}</Typography>
            <Typography>Panchayat: {viewDetailWard.assignedWard.panchayatName}</Typography>
          </> : <span>No detail found</span>}
          <Typography>

          </Typography>
          {/* <span>{JSON.stringify(viewDetailWard)}</span> */}
        </Box>
      </Modal>
      <>
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Select what to delete
            </Typography>
            <Box>
              <FormGroup
                onChange={(e) => {
                  let tempChecked = { ...checkedOptions };
                  tempChecked[e.target.value] = e.target.checked;
                  setCheckedOptions(tempChecked);
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox value="reg" checked={checkedOptions["reg"]} />
                  }
                  label="Delete Registration"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value="telemetry"
                      checked={checkedOptions["telemetry"]}
                    />
                  }
                  label="Delete Telemetry Data"
                />
              </FormGroup>
            </Box>
            {currentDevice ? (
              <Box sx={{ py: 2 }}>
                <Typography>Selected Device</Typography>
                <Grid container>
                  <Grid item md={6}>
                    <Typography>IMEI: {currentDevice.imei}</Typography>
                    <Typography>
                      Barcode:{" "}
                      {currentDevice.barcode
                        ? currentDevice.barcode
                        : "Not Found"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              ""
            )}
            <Box
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                style={{ marginLeft: 10 }}
                onClick={() => {
                  setCurrentDevice(null);
                  handleCloseModal();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={
                  Object.values(checkedOptions).filter((e) => e).length == 0
                }
                variant="contained"
              >
                Submit
              </Button>
              {/* <span>Clicking Submit will delete selected data</span> */}
            </Box>
          </Box>
        </Modal>
      </>
    </>
  );
}
