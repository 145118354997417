export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const title = {
  label1: "Bihar Government",
  label2: "Har Ghar Nal Jal Yojna",
};

export const colors = {
  textGreen: "#10b759",
  textYellow: "#fbbc06",
};

export const priColor = "#727cf5";

export const operationalInitialValue = {
  nonFunctional: 0,
  functional: 0,
  functionalWLS: 0,
  functionalPWS: 0,
  nonFunctionalWLS: 0,
  nonFunctionalPWS: 0,
};

export const DASHBOARD_URL = [
  {
    url: "https://jjmphed.hidashboard.com",
    name: "Public Health Engineering Department",
    enable: true,
  },
  {
    url: "https://jjmprd.rollzdashboard.com",
    name: "Panchayati Raj Department",
    enable: false,
  },
  // {
  //   url: "https://udhd.rollzdashboard.com",
  //   name: "Urban Development and Housing Department",
  //   enable: false,
  // },
];

export const WELCOME_FROM = "AYNIC / HORIZONOUS User"
export const BRAND_NAME = "Horizons Ingenious"
export const POWERED_BY = "A Y N I C ® / HORIZONS INGENIOUS"