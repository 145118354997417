import { Typography } from "@mui/material";
import { title } from "../constants"
import logo from "../images/logo_white.png";

const BrandBanner = () => {
    return <div style={{ display: "flex", flexDirection: "row" }}>
        <img src={logo} width={"10%"} />
        <div style={{ display: "flex", flexDirection: "column-reverse", marginLeft: 10 }}>
            <Typography color="textSecondary" variant="h7" >Powered By <span style={{ color: "#727cf5" }}>{title.label1}</span></Typography>
            <Typography color="textPrimary" variant="h6">
                {title.label2}
            </Typography>
        </div>
    </div>
}

export default BrandBanner